import Modal, {ModalColorScheme} from '@/components/shared/Modal/Modal';
import {ModalSizeEnum} from '@/enums';
import {removeHash, usePushState} from '@/hooks/usePushState';

import Contact from './Contact';

export default function ContactModal() {
  const {targetMatched} = usePushState({targetHash: '#contact-sales'});

  return (
    <Modal
      handleClose={removeHash}
      id="plus-contact-modal"
      isActive={targetMatched}
      modalSize={ModalSizeEnum.Full}
      colorScheme={ModalColorScheme.Dark}
      className="[&_.richtext_a]:text-button-dark-primary-bg [&_.richtext_a:active]:text-lime-70 [&_[data-component-name='close']]:-top-24 [&_[data-component-name='close']_svg]:w-6 [&_[data-component-name='close']_svg]:h-6 plus-contact-form-background"
    >
      <Contact />
    </Modal>
  );
}
